.blogPage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  padding-bottom: 10vh;
  font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #707070;
  font-size: 3em;
  font-weight: 200;
  background: white;
}
