footer {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px 0px 40px 0px;
  background-image: linear-gradient(to bottom, #ffffff 85%, black);
}

footer a {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer img {
  min-width: 30px;
  width: 12%;
  transition: transform 0.3s cubic-bezier(.46,.06,.28,.99);
}

footer a:hover img {
  transform: translateY(-6px);
}
