.notFound {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #707070;
  font-size: 3em;
  font-weight: 200;
  font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  background: white;
}

@media only screen and (max-width: 768px) {

  .notFound {
    height: 86vh;
  }

}
