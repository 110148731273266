#minus_logo {
  width: 4vw;
  height: 4vw;
  min-width: 70px;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.outer_border {
  flex: 1;
  position: absolute;
  width: 4vw;
  height: 4vw;
  min-width: 70px;
  min-height: 70px;
  border-radius: 200px;
  border: 3px solid #707070;
  border-style: dashed;
  animation: outerAnimation 21s ease-in-out infinite;
}

.inner_border {
  flex: 1;
  position: absolute;
  width: 2.4vw;
  height: 2.4vw;
  min-width: 45px;
  min-height: 45px;
  border-radius: 200px;
  border: 3px solid #707070;
  animation: innerAnimation 21s ease-in-out infinite;
}

.orbital {
  width: 9px;
  height: 9px;
  position: relative;
  top: -5.5px;
  background: #707070;
  border-radius: 10px;
  animation: orbital 6s ease-in-out infinite;
}

.bar1 {
  height: 3px;
  width: 30%;
  background: #707070;
  transition: transform 0.5s cubic-bezier(.36,-1.14,.52,1.84);
  transform: rotate(0deg);
}

#minus_logo:hover .bar1 {
  transform: rotate(-30deg);
}

@keyframes outerAnimation {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes innerAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes orbital {
  0% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(-3px);
  }
}
