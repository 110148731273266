a {
  text-decoration: none;
}

.childrenContent {
  font-size: 1.15em;
}

#carousel {
  width: 100%;
  min-height: 80vh;
}

#carousel .inner {
  display: flex;
  flex-direction: row;
  transition: transform 1s cubic-bezier(.46,.06,.28,.99);
  justify-content: center;
  align-items: center;
}

#carousel #project {
  flex: 1;
  font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  color: black;
}

#carousel #project h1 {
  font-weight: 500;
  border-bottom: 2px dashed black;
  padding: 20px;
  width: 95%;
}

#carousel .leftArrow {
  left: 0;
}

#carousel .rightArrow {
  right: 0;
}

#carousel .rightArrow, #carousel .leftArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  z-index: 1;
  position: absolute;
  margin-top: 15%;
}

#carousel .leftArrow img, #carousel .rightArrow img {
  width: 30%;
  transition: width 0.2s cubic-bezier(.46, .06, .28, .99), transform 0.2s cubic-bezier(.46, .06, .28, .99);
}

#carousel .leftArrow a, #carousel .rightArrow a {
  text-align: center;
}

#carousel .leftArrow:hover img, #carousel .rightArrow:hover img {
  width: 35%;
}

#carousel .rightArrow:hover img {
  transform: translateX(-5px);
}

#carousel .leftArrow:hover img {
  transform: translateX(5px);
}

#carousel #project .projectInner {
  width: 60%;
}

#carousel #project img {
  width: 100%;
}

#carousel #project .rotatingImage {
  transform-style: preserve-3d;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-perspective: 1000px;
  -webkit-perspective-origin: 50% 0;
}

#carousel #project .shadow {
  height: 20px;
  width: 78%;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 1;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 80%);
}

.shadowContainer {
  height: 25px;
  margin-top: 7%;
}

#sectionHeading {
  flex: 1;
  -webkit-font-smoothing: antialiased;
  font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
  font-size: 3em;
  animation: sectionHover 10s ease-in-out infinite;
  text-align: center;
}

@keyframes hover {
  0% {
    top: 0px;
  }
  50% {
    top: -10px;
  }
  100% {
    top: 0px;
  }
}

@keyframes sectionHover {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  25% {
    transform: translateY(-75px) rotate(5deg);
  }
  50% {
    transform: translateY(0px) rotate(10deg);
  }
  75% {
    transform: translateY(-75px) rotate(5deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
}

.projectContent {
  display: flex;
}

.projectContent .imageLink {
  flex: 4;
  padding: 10%;
  perspective: 80em;
}

.projectContent .stack {
  flex: 1
}

#carousel #project .projectInner .stack h3 {
  border-bottom: 3px solid #707070;
  width: 60%;
  padding-bottom: 12px;
}

@media only screen and (max-width: 768px) {

  #carousel #project h1 {
    font-size: 1.1em;
    width: 85%;
  }

  #carousel {
    min-height: 100vh;
    width: 100vw;
    overflow-x: hidden;
  }

  #carousel .rightArrow, #carousel .leftArrow {
    display: none;
  }

  #carousel #project .projectInner {
    width: 80%;
  }

  .projectContent .imageLink {
    padding: 5%;
  }

  #carousel .inner {
    min-height: 100vh;
  }

  .childrenContent {
    font-size: 0.8em;
  }

  #sectionHeading {
    font-size: 2em;
  }

}
