body {
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
}

.page-enter {
  transform: translateX(100vw);
}

.page-enter-active {
  transform: translateX(0vw);
  transition: transform 800ms;
  transition-delay: 100ms;
}

.page-exit {
  transform: translateX(0vw);
  transition: transform 800ms;
  transition-delay: 100ms;
  position: absolute;
  top: 0;
}

.page-exit-active {
  transform: translateX(-100vw);
  transition: transform 800ms;
  transition-delay: 100ms;
}

.page-enter #carousel .leftArrow,
.page-enter #carousel .rightArrow,
.page-exit #carousel .leftArrow,
.page-exit #carousel .rightArrow {
  margin-top: 4%;
}
