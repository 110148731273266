#portfolioBody {
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
}

.portfolioPage {
  width: 100vw;
  overflow-x: hidden;
}

#portfolioBody .left, #portfolioBody .right {
  flex-grow: 1;
  font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  padding-bottom: 40px;
  box-shadow: 0px 1px 10px #101010;
  transition: flex-grow 0.5s ease-in-out;
  transform: translateZ(0);
  text-align: center;
}

#portfolioBody .left:hover, #portfolioBody .right:hover {
  flex-grow: 2;
}

#portfolioBody .right {
  background: black;
  color: #707070;
}

#portfolioBody .left {
  color: #707070;
}

#portfolioBody a {
  text-decoration: none;
}

#portfolioBody .profilePic {
  margin-top: 36px;
  width: 10vw;
  height: 10vw;
  background-position: center;
  background-size: 100%;
  border-radius: 15vw;
}

#portfolioBody .right .btn {
  background-color: #707070 !important;
}

@media only screen and (max-width: 768px) {

  #portfolioBody {
    flex-direction: column;
    margin-bottom: 35px;
  }

  #portfolioBody h1 {
    font-size: 1.4em;
  }

  #portfolioBody .profilePic {
    margin-top: 20px;
    width: 20vw;
    height: 20vw;
  }

  #portfolioBody .left, #portfolioBody .right {
    padding-bottom: 10px;
  }

}
